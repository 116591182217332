export default {
  computed: {
    isHomeOrganic() {
      return ['pt-br', 'es'].includes(this.$i18n.locale)
    },

    isTestLatam() {
      const latam = ['es-co', 'es-mx']

      return [].includes(this.$i18n.locale) && this.$route.query.v === 't'
    },
  },
}
